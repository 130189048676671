export const cartProductList = {
  imageWidth: 145,
  imageHeight: 180,
  tableColspan: 4,
  showErrorNotification: false,
  showSuccessMessagePerRow: false,
  sortProductsByFlashErrors: true,
  storeNearFieldMaxLength: 256,
  showStaticTextInsteadOfShippingLabel: false,
  productAvailabilityModalCancelButtonClass:
    'vf-button--secondary vf-shipment-store-modal__btn',
  showStsInfoInProductAvailabilityModal: true,
  showGeneralFlashNotificationOnCart: true,
  showFavoritesNotificationOnCart: false,
  showSavedForLaterProductsSeparatorOnMobile: false,
  savedForLaterProductsHeaderTag: 'td',
  productIdPropertyName: 'masterId',
};
