export const productGallery = {
  enableQuickShopClass: true,
  enableSwipe: true,
  maxGridImageCountDesktop: 8,
  maxGridImageCountMobile: 8,
  thumbnailWidth: 50,
  thumbnailHeight: 63,
  quickShopThumbnailSpaceBetween: 13,
  maximizeIconSize: '1rem',
  presets: {
    main: 'wid=800&hei=1004&fmt=jpeg&qlt=50&resMode=sharp2&op_usm=0.9,1.5,8,0',
    thumbnail:
      'wid=50&hei=63&fmt=jpeg&qlt=50&resMode=sharp2&op_usm=0.9,1.0,8,0',
    zoom: 'wid=1600&hei=1984&fmt=jpeg&qlt=90&resMode=sharp2&op_usm=0.9,1.7,8,0',
  },
  quickShopSwiperDirection: 'vertical',
  thumbnailsMobileEnabled: true,
  usePresets: true,
  swiperControlsClass: { prev: 'disabled', next: 'disabled' },
  showCustomsLogo: true,
  enableVisualSearch: true,
  imageWidth: 1284,
  imageHeight: 1605,
  closeButtonVisible: false,
  dotsNavigationEnabled: true,
  arrowsNavigationEnabled: true,
};
