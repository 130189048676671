export const categoryFilters = {
  accordionsOpen: { small: false, medium: false, large: true },
  sticky: true,
  showResetButton: true,
  filterNumberOfFacetsToShowSmall: 7,
  filterNumberOfFacetsToShowMedium: 7,
  filterNumberOfFacetsToShowLarge: 7,
  filterNumberOfFacetsToShow: 7,
  sizeFiltersVariant: 'text',
};
