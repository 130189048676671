export const orderCard = {
  attributesOrder: ['SIZE', 'COLOR'],
  showProductQuantities: false,
  showNotificationCloseButton: false,
  showCosts: true,
  showDividerAfterProduct: false,
  showDifferentProductDescriptions: false,
  orderNotificationIcon: 'info',
  viewDetailsButtonClass: 'vf-button--secondary vf-button--medium',
  orderDetailsButtonClass: 'vf-button--secondary vf-button--medium',
  propertyClass: 'vf-property--small',
  productCardSize: {
    small: 6,
    medium: 6,
    large: 4,
  },
  imageSizes: {
    small: {
      width: 214,
      height: 266,
      quality: 85,
    },
    medium: {
      width: 400,
      height: 497,
      quality: 85,
    },
    large: {
      width: 214,
      height: 266,
      quality: 85,
    },
  },
};
