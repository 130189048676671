export const productsGrid = {
  gridSizes: [
    {
      name: 'normal',
      icon: '',
      selectedIcon: '',
      showOnMobile: true,
      grid: {
        small: 6,
        medium: 6,
        large: 4,
      },
      product: {
        small: {
          badges: true,
          scrollableImages: false,
          showSwiperIcons: false,
          colors: 3,
        },
        medium: {
          badges: true,
          scrollableImages: false,
          showSwiperIcons: false,
          colors: 4,
        },
        large: {
          badges: true,
          scrollableImages: false,
          showSwiperIcons: false,
          colors: 4,
        },
      },
    },
  ],
  loadPrevious: {
    isLoadPreviousEnabled: false,
    loadPreviousAdditionalButtonClasses: 'vf-button--secondary',
  },
  defaultGridSize: 'normal',
  imageSizes: {
    small: {
      width: 390,
      height: 489,
      quality: 50,
      resMode: 'sharp2',
      opSum: '0.9,1.0,8,0',
    },
    medium: {
      width: 492,
      height: 617,
      quality: 50,
      resMode: 'sharp2',
      opSum: '0.9,1.0,8,0',
    },
    large: {
      width: 492,
      height: 617,
      quality: 50,
      resMode: 'sharp2',
      opSum: '0.9,1.0,8,0',
    },
  },
};
