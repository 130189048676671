export const facetConfig = {
  'Shoe Styles': {
    crawlable: false,
    displayType: 'thumbnail',
    fallbackCode: '',
    code: 'Shoe Styles',
  },
  // We are keeping this for fr-ca locale, as this is being used in Coremedia for fr-ca
  // There is ongoing effort (https://digital.vfc.com/jira/browse/GLOBAL15-39203) to make it similar for all locales
  // We can remove one this once it is fixed in Coremedia
  ClassicShoeStyles: {
    crawlable: false,
    displayType: 'thumbnail',
    fallbackCode: '',
    code: 'ClassicShoeStyles',
  },
  sizeManShoes: {
    displayType: 'chip',
    showAllVariants: true,
  },
  sizeBoyShoes: {
    displayType: 'chip',
    showAllVariants: true,
  },
  sizeWomanShoes: {
    displayType: 'chip',
    showAllVariants: true,
  },
  sizeKidsShoes: {
    displayType: 'chip',
    showAllVariants: true,
  },
  sizeToddlerShoes: {
    displayType: 'chip',
    showAllVariants: true,
  },
  Price: {
    displayType: 'range',
    showAllVariants: true,
  },
};
