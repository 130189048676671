export const orderDetails = {
  backToHistoryLinkCustomClass: '',
  getDirectionButtonClass:
    'vf-button--secondary vf-button--medium vf-button--mobile-full vf-order-summary__direction-button',
  cancelledItemsLabelClass: 'vf-order-shipment-label--cancelled',
  returnButtonSize: 'lg',
  defaultStoreName: 'VANS STORE',
  notificationType: 'info',
  showNotificationClose: false,
  showNotificationText: false,
  showShippingAddress: false,
  showCostsExpanded: false,
  showDividerAfterDate: false,
  showDeliveryAsText: false,
  showEddAndTrackingInSeparateLines: false,
  showItemPrice: false,
  showGiftOption: false,
  showFreeInsteadZero: false,
  hideMultipleTrackingLabels: false,
  appendColorCode: false,
  showSmallDescription: true,
  showTrackingBeforeProducts: true,
  showFlatTrackOrderText: true,
  groupItemsFromShipment: true,
  addressFormat: [
    '{{addressLine1}}',
    '{{addressLine2}}',
    '{{addressLine3}}',
    '{{city}}, {{province}} {{postalCode}}',
    '{{country}}',
  ],
  pickupPersonInfoFormat: ['{{firstName}} {{lastName}}', '{{email}}'],
  pickupAddressFormat: [
    '{{addressLine1}}',
    '{{addressLine2}}',
    '{{city}}, {{province}} {{postalCode}}',
  ],
  personInfoFormat: [
    '{{firstName}} {{lastName}}',
    '{{phoneNumber}}',
    '{{email}}',
  ],
  inlineMobileQtyPrice: true,
  imageWidth: 140,
  imageHeight: 174,
};
