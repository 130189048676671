export const loyaltyOrderRegister = {
  hideAccruedPoints: true,
  buttonVariant: 'primary',
  logo: {
    icon: 'vansfamily',
    color: 'black',
    width: 60,
    height: 41,
  },
};
