export const dataListingTable = {
  addItemButtonClass:
    'vf-button--secondary vf-button--large vf-button--mobile-full add-button',
  headingWithoutSubtitle: false,
  tableClass: 'dl-table',
  firstColumnTextAttributes: {},
  firstColumnSmallBottomMargin: false,
  baseColumnTextAttributes: {},
  actionColumnButtonClass: '',
  actionColumnClass: '',
  actionColumnAttributes: {
    large: 1,
    medium: 1,
  },
  headingAttributes: {
    titleModifier: 'title-4',
  },
  iconSize: {
    width: 25,
    height: 16,
  },
};
