export const payPal = {
  checkout: {
    color: 'gold',
    shape: 'rect',
    height: {
      base: 43,
      'mini-cart': 45,
    },
  },
  page: {
    color: 'white',
    shape: 'rect',
    height: {
      base: 45,
    },
  },
  skipShippingAddressChecking: true,
};
