export const emailPreferences = {
  headingModifier: 'title-4',
  emailModifier: 'subtitle-2',
  controlsClass: undefined,
  submitBtnColor: 'secondary',
  submitBtnVariant: 'border',
  submitBtnSize: 'md',
  submitBtnClass: 'vf-email-preferences__button',
  submitButton: {
    color: 'primary',
    variant: '',
    size: 'md',
    class: 'vf-email-preferences__button',
  },
  cancelButton: {
    color: 'secondary',
    variant: 'border',
    size: 'md',
    class: 'vf-email-preferences__button',
  },
  ctaButton: {
    color: 'secondary',
    variant: 'border',
    size: 'md',
    class: 'vf-email-preferences__button',
    context: null,
  },
  showEmail: false,
};
