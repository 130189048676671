export const orderSuccess = {
  summaryHeadingTitleModifier: 'title-4',
  summaryHeadingLevelModifier: 4,
  pickupDetailsAtTop: false,
  getDirectionButtonClass:
    'vf-button--secondary vf-button--mobile-full vf-button--medium vf-order-summary__direction-button',
  getDirectionButtonIcon: '',
  showDeliveryDataInOneRow: false,
  pickupAddressFormat: [
    '{{addressLine1}}',
    '{{addressLine2}}',
    '{{city}}, {{province}} {{postalCode}}',
  ],
  pickupPersonInfoFormat: ['{{firstName}} {{lastName}}', '{{email}}'],
  altPickupPersonInfoFormat: [
    '{{altFirstName}} {{altLastName}}',
    '{{altEmail}}',
  ],
  personInfoFormat: ['{{firstName}} {{lastName}}', '{{phone}}', '{{email}}'],
  addressFormat: [
    '{{addressLine1}}',
    '{{addressLine2}}',
    '{{addressLine3}}',
    '{{city}}, {{province}} {{postalCode}}',
    '{{country}}',
  ],
};
