export const checkoutPaymentInfo = {
  addNewCardButtonComponent: 'ThemeLink',
  addNewCardButtonVariant: '',
  addNewCardButtonClass: '',
  paymentMethodOptionClass: 'col small-12 medium-12 large-12',
  hiddenLabels: ['paypal'],
  showMethodVariantsSeparately: false,
  showCardLogo: false,
  showCardAddress: false,
  skipHandlingNonCreditCardChange: false,
  isApplePayDefault: false,
};
