export const findInStore = {
  defaultDistance: 5,
  useLocationClass: 'full-width justify-center',
  searchButtonClass: 'full-width justify-center',
  findInStoreComponent: 'ThemeButton',
  findInStoreComponentSize: 'lg',
  findInStoreComponentColor: 'secondary',
  leftColumnLargeSize: 6,
  showStsMessageIfProductIsAvailable: true,
  imageWidth: 110,
  imageHeight: 137,
};
