export const giftOption = {
  giftBoxImage: 'https://images.vans.com/is/image/Vans/Gift%20Box%20with%20GC',
  giftBoxImageWidth: 235,
  giftBoxImageHeight: 173,
  itemImageWidth: 66,
  itemImageHeight: 78,
  editableItemImageWidth: 110,
  editableItemImageHeight: 129,
  maxMessageCharacters: 200,
};
