const enConfigFactory = ({ iframe_url }) => ({
  country: {
    title: 'VANS DEALERS / STORE LOCATIONS',
    meta_data: {
      title: 'Vans Locations/Store Directory',
      description:
        'Directory of Vans locations. Find a local Vans near you for Shoes, Clothing & More.',
      keywords: '',
      main_breadcrumb_url_name: 'Select a state',
    },
  },
  state: {
    cities: 'Cities',
    city_title: 'Vans Dealers / Store Locations in {state_name}',
    meta_data: {
      title: 'Vans Locations in {state} ({state_code})',
      description:
        'Visit your local {state}, {state_code} Vans location for products and services.',
      keywords: '',
      main_breadcrumb_url_name: 'Select a state',
    },
  },
  city: {
    title: 'Vans Dealers / Store Locations in {city_state}',
    meta_data: {
      title: 'Vans Locations {city}, {state}',
      description:
        'Visit your local {city_state} Vans location for products and services.',
      keywords: '',
      main_breadcrumb_url_name: 'Select a state',
    },
  },
  store: {
    store_map_title:
      'STORE DETAILS FOR VANS - SHOES, CLOTHING & MORE DEALER / STORE LOCATION IN {city}, {province} | {id}',
    store_meta_title: '{name} in {city}, {province}, {postalcode}',
    store_meta_description:
      'Shop at {name} in {city}, {province} for the latest VANS shoes, clothing, accessories, and more!',
    appData: {
      appkey: '266588F2-0EFD-11EB-B231-64499CAB76FA',
      iframe_url,
      containedIn: 'Mall/Shopping Center Column Header',
      smsButtonText: 'Send to Mobile',
      emailButtonText: 'Send to Email',
      directionText: 'Get directions',
      openings_text: 'HOURS',
      share_text: 'SHARE',
      info_text: 'INFO',
      pictureText: 'STORE PHOTO',
      mapUrl: 'https://www.google.com/maps/dir',
    },
    store: {
      storeCarriesLabel: 'This Store Carries',
      carries: {
        footwear: 'Footwear',
        apparel: 'Apparel',
        snowboard_boots: 'Snowboard Boots',
        surf: 'Surf',
        pro_skate: 'Skate Footwear',
      },
    },
    logo:
      'https://static.vans.com/ui/www/images/logos/logo.hash-6a49946697efd93990ed1c389cad00d5.svg',
    image:
      'https://images.vans.com/is/content/VansBrand/english/panels/customs/HO20/ho20_simpsons_homepage-secondarybanner.gif',
    daysOfWeek: [
      {
        dayOfWeek: 'Monday',
        key: 'm',
      },
      {
        dayOfWeek: 'Tuesday',
        key: 't',
      },
      {
        dayOfWeek: 'Wednesday',
        key: 'w',
      },
      {
        dayOfWeek: 'Thursday',
        key: 'thu',
      },
      {
        dayOfWeek: 'Friday',
        key: 'f',
      },
      {
        dayOfWeek: 'Saturday',
        key: 'sa',
      },
      {
        dayOfWeek: 'Sunday',
        key: 'su',
      },
    ],
  },
});

export const storeData = {
  'en-ca': enConfigFactory({
    iframe_url: 'https://locations.vans.com/mapwidget.html',
  }),
  'en-us': enConfigFactory({
    iframe_url: 'https://locations.vans.com/mapwidget.html',
  }),
  'fr-ca': {
    country: {
      title: 'CONCESSIONNAIRES VANS / EMPLACEMENTS DES MAGASINS',
      meta_data: {
        title: 'Emplacements Vans / Répertoire des magasins',
        description:
          'Répertoire des emplacements de Vans. Trouvez un Vans près de chez vous pour les chaussures, les vêtements et plus.',
        keywords: '',
        main_breadcrumb_url_name: 'Sélectionner un état',
      },
    },
    state: {
      cities: 'Villes',
      city_title:
        'Concessionnaires VANS / Emplacements des magasins en {state_name}',
      meta_data: {
        title: 'Vans Emplacements en {state} ({state_code})',
        description:
          'Rendez-vous dans votre magasin Vans {state}, {state_code} pour découvrir les produits et services',
        keywords: '',
        main_breadcrumb_url_name: 'Sélectionner un état',
      },
    },
    city: {
      title: 'Emplacements Vans / Répertoire des magasins en {city_state}',
      meta_data: {
        title: 'Vans Emplacements {city}, {state}',
        description:
          'Rendez-vous dans votre magasin Vans {city_state} pour découvrir les produits et services',
        keywords: '',
        main_breadcrumb_url_name: 'Sélectionner un état',
      },
    },
    store: {
      store_map_title:
        'DÉTAILS DU MAGASIN POUR VANS - CHAUSSURES, VÊTEMENTS ET PLUS / EMPLACEMENTS DES MAGASINS EN {city}, {province} | {id}',
      store_meta_title: '{name} à {city}, {province}, {postalcode}',
      store_meta_description:
        'Magasinez chez {name} à {city}, {province} pour trouver les plus récents vêtements, chaussures et accessoires de Vans, et plus encore!',
      appData: {
        appkey: '266588F2-0EFD-11EB-B231-64499CAB76FA',
        iframe_url: 'https://locations.vans.com/mapwidget.html',
        containedIn: 'Mall/Shopping Center Column Header',
        smsButtonText: 'Envoyer un sms',
        emailButtonText: 'Envoyer un email',
        directionText: 'Obtenir des directions',
        openings_text: 'HEURES',
        share_text: 'PARTAGER',
        info_text: 'INFO',
        pictureText: 'PHOTO DU MAGASIN',
        mapUrl: 'https://www.google.com/maps/dir',
      },
      store: {
        storeCarriesLabel: 'Ce magasin porte',
        carries: {
          footwear: 'Chaussures',
          apparel: 'Vêtements',
          snowboard_boots: 'Chaussures de ski',
          surf: 'Surf',
          pro_skate: 'Chaussures de skate',
        },
      },
      logo:
        'https://static.vans.com/ui/www/images/logos/logo.hash-6a49946697efd93990ed1c389cad00d5.svg',
      image:
        'https://images.vans.com/is/content/VansBrand/english/panels/customs/HO20/ho20_simpsons_homepage-secondarybanner.gif',
      daysOfWeek: [
        {
          dayOfWeek: 'Lundi',
          key: 'm',
        },
        {
          dayOfWeek: 'Mardi',
          key: 't',
        },
        {
          dayOfWeek: 'Mercredi',
          key: 'w',
        },
        {
          dayOfWeek: 'Jeudi',
          key: 'thu',
        },
        {
          dayOfWeek: 'Vendredi',
          key: 'f',
        },
        {
          dayOfWeek: 'Samedi',
          key: 'sa',
        },
        {
          dayOfWeek: 'Dimanche',
          key: 'su',
        },
      ],
    },
  },
};
