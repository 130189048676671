export const viewMoreProductsButtons = {
  showViewAll: true,
  showProgressBar: true,
  maxQuery: 200,
  infiniteScroll: true,
  infiniteScrollOffset: {
    large: '435px',
    medium: '460px',
    small: '280px',
  },
};
