const imageSize = {
  width: 88,
  height: 109,
};

const imageSizeSmall = {
  width: 100,
  height: 124,
};

export const searchSuggestions = {
  buttonClass: 'vf-button--secondary',
  imageSizes: {
    small: imageSizeSmall,
    medium: imageSize,
    large: imageSize,
  },
  showTopSearchesHeading: true,
  markQueryInResultLabels: true,
  showSeeMoreButtonInResults: false,
  allowResultsOverflow: false,
  showSearchSuggestionsPhrase: false,
  showSearchSuggestionsCloseButton: false,
  showMoreButton: false,
  useDeviceBreakpoints: true,
};
