export const seoRelatedWidget = {
  showRelatedProducts: true,
  showRelatedSearches: true,
  // for visually split columns with carousel view
  relatedSearchesCount: 6,
  // for related product image carousel view
  slidesToShow: { lg: 3, md: 3, sm: 2 },
  relatedProductsDisplayMode: 'imgTitleDescriptionCta',
  relatedSearchesDisplayMode: 'split',
};
