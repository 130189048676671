import { CheckoutSidebarConfigType } from '@vf/shared/src/theme/types/CheckoutSidebar';

export const checkoutSidebar: CheckoutSidebarConfigType = {
  buttonContext: 'dark',
  ctaButton: {
    class: '',
    color: 'primary',
    size: 'lg',
    context: 'dark',
  },
  shareMyCartButton: {
    class: '',
    color: 'secondary',
    size: 'lg',
    context: null,
  },
  orderSummaryClass: 'vf-text--center',
  applyPromoCodeBtnClass: 'button-size',
  promocodeAccordionClass: 'vf-accordion--gray-background',
  promocodeAccordionStrongHeader: true,
  dividers: {
    default: {
      orderSummaryBottom: true,
      editCartLinkBottom: true,
    },
  },
  showShippingTimeAndLabelOneLine: false,
  hidePaypalForEmployee: true,
  applePayButtonVariant: 'secondary',
  mergeNarvarAndPickupInfo: true,
  isKlarnaAllowedForMixedOrder: true,
  hideInvalidCoupons: false,
};
