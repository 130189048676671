export const staticCheckout = {
  footer: {
    brandLogo: {
      width: 73,
      height: 32,
      label: 'VANS',
    },
  },
  giftCard: {
    maxGiftCards: 2,
  },
  productCard: {
    imageWidth: 94,
    imageHeight: 117,
  },
  payPal: {
    color: 'gold',
    shape: 'rect',
    height: 54,
    borderRadius: 4,
  },
  orderSuccess: {
    showCloseIcon: false,
  },
  showBuyInStoreInfo: false,
  showStoreEmployeeInfo: true,
};
