export const productActionButtons = {
  actionButtonSize: 'md',
  actionIconSize: '16px',
  wrapperPaddings: {
    quickshop: '0 0 2rem 0',
    default: '0',
  },
  findInAStoreButtonColor: 'secondary',
  scrollToErrorOffset: 20,
  scrollToErrorOffsetWithTopStickyHeader: 90,
};
